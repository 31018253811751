<template>
  <el-tooltip
    class="box-item"
    effect="dark"
    content="手动同步OA人员"
    placement="bottom"
  >
    <el-button type="primary" link icon="Refresh" @click="this.visible = true"
      >手动同步</el-button
    >
  </el-tooltip>
  <el-dialog
    title="手动同步"
    v-model="visible"
    width="1100"
    draggable
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :destroy-on-close="true"
  >
    <el-form :model="query" ref="query" :inline="true">
      <el-form-item label="手机号码">
        <el-input
          v-model="query.q"
          placeholder="请输入手机号码查询"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          round
          @click="onSearch"
          icon="search"
          :loading="loading"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <div style="margin-top: 30px">
      <el-table :data="dataList" border stripe>
        <el-table-column label="来源" width="100">
          <template #default="scope">
            {{ scope.row.source == 0 ? "本地" : "来自OA" }}
          </template>
        </el-table-column>
        <el-table-column label="存在与否" width="100">
          <template #default="scope">
            {{ scope.row.isExist ? "已存在" : "不存在" }}
          </template>
        </el-table-column>
        <el-table-column
          label="OAId"
          prop="oaId"
          min-width="260"
        ></el-table-column>
        <el-table-column label="姓名" prop="name" width="110"></el-table-column>
        <el-table-column
          label="手机号码"
          prop="mobile"
          width="140"
        ></el-table-column>
        <el-table-column
          label="创建时间"
          prop="createTime"
          width="180"
        ></el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-popconfirm
              width="220"
              v-if="scope.row.source == 1"
              title="您确定要同步？"
              @confirm="onSubmit(scope.row)"
            >
              <template #reference>
                <el-button
                  size="small"
                  type="primary"
                  link
                  icon="Right"
                  :loading="saving"
                  >立即同步</el-button
                >
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- <template #footer>
      <el-button @click="visible = false" :disabled="saving">取消</el-button>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确定</el-button
      >
    </template> -->
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      loading: false,
      saving: false,
      query: {
        q: "",
      },
      dataList: [],
    };
  },
  methods: {
    /**
     * 查询
     */
    onSearch() {
      this.loading = true;
      this.$http
        .get("admin/v1/oaMqLog/get_mobile?mobile=" + this.query.q)
        .then((res) => {
          if (res.code == 0) {
            this.dataList = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 提交同步
     */
    onSubmit(item) {
      this.saving = true;
      this.$http
        .get("admin/v1/oaMqLog/sync?oaId=" + item.oaId)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg);
            this.visible = false;
          }
        })
        .finally(() => (this.saving = false));
    },
  },
};
</script>

<style scoped></style>
